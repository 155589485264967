enum FileType {
  /**
   * Profile avatar
   */
  PROFILE_AVATAR = "profile.avatar",
  /**
   * Lab requisition form
   */
  LAB_REQUISITION_FORM = "labs.requisition_form",
  /**
   * Lab result → result provided by the patient at some point
   */
  LAB_RESULT = "labs.user_result",
  /**
   * Pharmacy Insurance card
   */
  PHARMACY_INSURANCE_CARD = "benefits.pharmacy_insurance_card_photo",
  /**
   * Medical Insurance card
   */
  MEDICAL_INSURANCE_CARD = "benefits.medical_insurance_card_photo",
  /**
   * Prescription Prior auth
   */
  PRESCRIPTION_PRIOR_AUTH = "prescription.prior_auth",
  /**
   * Identification Document
   */
  IDENTIFICATION_DOCUMENT = "subscription.identity.id",
  /**
   * Identification Face Photo
   */
  IDENTIFICATION_FACE_PHOTO = "subscription.identity.face",
  /**
   * Denial Letter
   */
  DENIAL_LETTER = "care.denial_letter",
  /**
   * Letters of Medical Necessity
   */
  LETTERS_OF_MEDICAL_NECESSITY = "care.letter_of_medical_necessity",
  /**
   * Appeal Letters
   */
  APPEAL_LETTERS = "care.appeal_letter",
  /**
   * Letters and Information from Insurance
   */
  LETTERS_AND_INFORMATION_FROM_INSURANCE = "care.letter_and_information_from_insurance",
  /**
   * Medical Claim EOBs
   */
  MEDICAL_CLAIM_EOBS = "care.medical_claim_eob",
  /**
   * Medical Claim Invoices
   */
  MEDICAL_CLAIM_INVOICES = "care.medical_claim_invoice",
  /**
   * Medical Claim Denials
   */
  MEDICAL_CLAIM_DENIALS = "care.medical_claim_denial",
  /**
   * Clinical Summary
   */
  CLINICAL_SUMMARY = "care.clinical_summary"
}

export default FileType;
