import type { ActionSheetButton } from "@capacitor/action-sheet";
import { ActionSheet, ActionSheetButtonStyle } from "@capacitor/action-sheet";
import { Filesystem } from "@capacitor/filesystem";
import type { PickFilesResult } from "@capawesome/capacitor-file-picker";
import { FilePicker } from "@capawesome/capacitor-file-picker";
import type { PickedFile } from "@capawesome/capacitor-file-picker/dist/esm/definitions";
import { useMemo } from "react";
import type FileType from "src/constants/fileType";
import humanizeFileSize from "src/lib/humanizeFileSize";
import { isHybridApp } from "src/lib/platform";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { fileState } from "src/state/state";
import type { BlockingLoadingOverlayErrorProps } from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";

interface ActionSheetButtonWithHandler extends ActionSheetButton {
  handler?: () => void;
}

export interface FilesPickerParams {
  fileTypes?: string[];
  setFileDetails?: (
    fileDetails: {
      name: string;
      type: "img" | "pdf";
      size: string;
    } | null
  ) => void;
  onSelect?: (file: File) => void;
  setLoading?: (loading: boolean) => void;
  onChange?: (fileId: string) => void;
  setPreviewImageSrc?: (src: string | null) => void;
  namePrefix?: string;
  metaFileType?: FileType;
  tags?: string[];
  maxSize?: number;
  imageRatio?: number;
  camera?: {
    title?: string;
    description?: string;
  };
}

export class FilesPicker {
  params: FilesPickerParams;
  loader?: ReturnType<typeof BlockingLoadingOverlayController.startLoading>;
  static readonly defaultMaxSize = 20;
  static readonly defaultImageRatio = 1.414;
  static readonly defaultFileTypes = ["*/*"];

  constructor(params: FilesPickerParams) {
    this.params = params;
  }

  get maxSize() {
    return this.params.maxSize ?? FilesPicker.defaultMaxSize;
  }

  get imageRatio() {
    return this.params.imageRatio ?? FilesPicker.defaultImageRatio;
  }

  get fileTypes() {
    return this.params.fileTypes ?? FilesPicker.defaultFileTypes;
  }

  getLoader = () => {
    if (!this.loader) {
      this.loader = BlockingLoadingOverlayController.startLoading({
        bg: "transparent"
      });
    }
    const [done, fail] = this.loader;
    return [
      () => {
        done();
        this.loader = undefined;
      },
      (args: BlockingLoadingOverlayErrorProps = {}) => {
        fail(args);
        this.loader = undefined;
      },
      () => {
        BlockingLoadingOverlayController.endLoading();
        this.loader = undefined;
      }
    ];
  };

  deviceHasCamera = async (): Promise<boolean> => {
    const videoInputDevices = await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices.filter((device) => device.kind === "videoinput");
      });

    return videoInputDevices.length > 0;
  };

  uploadFile = async (
    file: File,
    options: {
      handlePreviewUrl?: (url: string | null) => void;
    }
  ) => {
    const [done, fail, end] = this.getLoader();
    try {
      this.params.setFileDetails?.({
        name: file.name,
        type: file.type.includes("image") ? "img" : "pdf",
        size: humanizeFileSize(file.size)
      });

      this.params.onSelect?.(file);

      // handle upload
      this.params.setLoading?.(true);

      const blobUrl = URL.createObjectURL(file);
      options.handlePreviewUrl?.(blobUrl);

      const nameWithPrefix = this.params.namePrefix
        ? `${this.params.namePrefix} - ${file.name}`
        : file.name;

      const result = await fileState.uploadFile({
        file,
        fileAttributes: {
          "file.type": this.params.metaFileType,
          "file.name": nameWithPrefix,
          "file.tags": this.params.tags
        }
      });

      if (result) {
        this.params.onChange?.(result.id);
        done();
      } else {
        options.handlePreviewUrl?.(null);
        this.params.setFileDetails?.(null);
        fail({
          title: translate("error_unknown_error")
        });
      }
    } catch (e) {
      reportErrorSentry(e);
      this.params.setFileDetails?.(null);
      end();
    }

    this.params.setLoading?.(false);
  };

  parsePickedFilesResult = async (
    result?: PickFilesResult
  ): Promise<File | undefined> => {
    const [, fail] = this.getLoader();
    const selected = result?.files[0];

    if (selected?.blob) {
      return new File([selected.blob], selected.name, {
        type: selected.mimeType
      });
    } else if (selected?.data) {
      const blob = FilesPicker.base64toBlob(selected.data, selected.mimeType);
      return new File([blob], selected.name, {
        type: selected.mimeType
      });
    } else if (selected?.path) {
      const readResult = await Filesystem.readFile({
        path: selected.path
      });

      const { data } = readResult;
      let blob: Blob | null = null;
      if (typeof data === "string") {
        blob = FilesPicker.base64toBlob(data, selected.mimeType);
      } else if (typeof data === "object") {
        blob = data;
      }

      if (blob) {
        return new File([blob], selected.name, {
          type: selected.mimeType
        });
      }
    }

    fail({ message: translate("error_file_not_readable") });
    return undefined;
  };

  validatePickedFile = (result: PickFilesResult): boolean => {
    const file = result.files[0] as PickedFile | undefined;
    const [, fail] = this.getLoader();

    if (!file) {
      fail({ message: translate("error_file_not_readable") });
      return false;
    }

    // check file size
    if (file.size > this.maxSize * 1024 * 1024) {
      fail({ message: translate("error.fileSize", { maxSize: this.maxSize }) });
      return false;
    }

    return true;
  };

  handleImagePicked = (result: PickFilesResult) => {
    const valid = this.validatePickedFile(result);
    if (!valid) {
      return;
    }

    void this.parsePickedFilesResult(result).then((file) => {
      if (file) {
        void this.uploadFile(file, {
          handlePreviewUrl: this.params.setPreviewImageSrc
        });
      }
    });
  };

  handleFilePicked = (result: PickFilesResult) => {
    const valid = this.validatePickedFile(result);
    if (!valid) {
      return;
    }

    const [selected] = result.files;

    //     check if its an image file
    if (selected.mimeType.includes("image")) {
      this.handleImagePicked(result);
      return;
    }

    void this.parsePickedFilesResult(result).then((file) => {
      if (file) {
        this.params.setPreviewImageSrc?.(null);
        void this.uploadFile(file, {});
      }
    });
  };

  pickImageFromGallery = () => {
    const [, , , end] = this.getLoader();
    void FilePicker.pickImages({
      limit: 1
    })
      .then(this.handleImagePicked)
      .catch((e: unknown) => end(e as Error));
  };

  pickFile = () => {
    const [, , end] = this.getLoader();
    void FilePicker.pickFiles({
      limit: 1,
      types: this.fileTypes
    })
      .then(this.handleFilePicked)
      .catch((e: unknown) => end(e as Error));
  };

  createGalleryOption =
    async (): Promise<ActionSheetButtonWithHandler | null> => {
      const allowedTypes = this.fileTypes.filter(
        (type) => type.includes("image") || type.includes("*/*")
      );

      if (!isHybridApp() || allowedTypes.length === 0) {
        return null;
      }

      return {
        title: translate("chooseFromGallery"),
        handler: this.pickImageFromGallery
      };
    };

  createUploadFileOption =
    async (): Promise<ActionSheetButtonWithHandler | null> => {
      return {
        title: translate("uploadFile"),
        handler: this.pickFile
      };
    };

  startPicker = async (options: { title?: string; message?: string } = {}) => {
    const buttonPromises = Promise.all([
      this.createGalleryOption(),
      this.createUploadFileOption()
    ]);

    const buttons = (await buttonPromises).filter(Boolean);

    if (buttons.length === 1) {
      buttons[0].handler?.();
      return;
    }

    const result = await ActionSheet.showActions({
      title: options.title,
      message: options.message,
      options: [
        ...buttons.map((button) => ({
          title: button.title,
          style: ActionSheetButtonStyle.Default
        })),
        {
          title: translate("cancel"),
          style: ActionSheetButtonStyle.Cancel
        }
      ]
    });

    const [, , end] = this.getLoader();

    const selectedButton = buttons[result.index] as
      | ActionSheetButtonWithHandler
      | undefined;

    if (selectedButton?.handler) {
      selectedButton.handler();
    } else {
      end();
    }
  };

  static base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}

export function useFilesPicker(params: FilesPickerParams) {
  const picker = useMemo(
    () =>
      new FilesPicker({
        ...params
      }),
    [params]
  );

  return {
    pickFile: picker.pickImageFromGallery,
    deviceHasCamera: picker.deviceHasCamera,
    startPicker: picker.startPicker
  };
}
