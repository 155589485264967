import type { CSSProperties, FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import {
  IconCrossXCloseMissed,
  IconDocumentFilePdfGradient,
  IconEditPencilPaperWrite,
  IconLargePlusSimpleAddCross,
  IconUpload
} from "src/constants/icons";
import type FileType from "src/constants/fileType";
import { FilesPicker, useFilesPicker } from "src/state/FilesCubit/FilesPicker";
import translate from "src/lib/translate";

const Card = styled.button`
  label: DocumentCard;
  position: relative;
  display: block;
  z-index: 1;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--opacity-0, 0);
  }

  svg {
    width: 4.4rem !important;
    height: auto !important;
  }

  &:focus-within,
  &:hover {
    background-color: #f9f8f5;
  }

  &[data-loading="true"] {
    pointer-events: none;
  }

  &[data-preview="true"] {
    svg {
      opacity: 0;
    }
  }
`;

const Clip = styled.div`
  label: Clip;
  padding: 3rem;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 2px solid var(--greys-light-gray, #e6e3db);
  background-color: var(--secondary-dark-cream, #f2efe7);
  aspect-ratio: var(--card-ratio, 1.41);
`;

const ImagePreview = styled.div`
  position: absolute;
  inset: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  transition:
    filter var(--ease-time) var(--ease-type),
    opacity var(--ease-time),
    transform var(--ease-time);
  transform: scale(1);

  [data-loading="true"] & {
    filter: blur(5px);
    opacity: 0.6;
    transform: scale(1.4);
    transition:
      filter 0s,
      opacity 0s,
      transform 10s linear;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
`;

const Title = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.02rem;

  [data-show-selected-titles="false"][data-preview="true"] &,
  [data-show-selected-titles="false"][data-loading="true"] & {
    opacity: 0;
  }

  [data-preview="true"] & {
    position: absolute;
    bottom: 0;
    z-index: 20;
    left: 0;
    right: 0;
    background-color: var(--greys-light-gray, #e6e3dbcc);
    color: var(--greys-charcoal, #212121);
    padding: 0.4rem 1rem 0.3rem;
    border-top: 2px solid var(--greys-light-gray, #e6e3db);
  }
`;

const Action = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%) translateZ(1px);
  z-index: 10;
  transition: opacity var(--ease-time) var(--ease-type);
  border-radius: 50%;
  background-color: var(--color-fog, #f5f5f5);
  --width: 2.3rem;
  width: var(--width);
  height: var(--width);
  display: grid;
  place-items: center;
  border: 2px solid var(--greys-light-gray, #e6e3db);
  opacity: 0;

  svg {
    width: 1.3rem !important;
    height: auto !important;
  }

  &[data-action="delete"] {
    background-color: var(--color-charcoal);
    color: white;
    border-color: var(--color-charcoal);

    svg {
      width: 1.9rem !important;
    }
  }

  [data-preview="true"] & {
    opacity: 1;

    svg {
      opacity: 1;
    }
  }
`;

const PreviewDocument = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 20;
  background: linear-gradient(
    211deg,
    #ffdfb4 -25.3%,
    #fddee9 35.64%,
    #c1d7fb 92.9%
  );

  h5,
  h6 {
    font-size: 1rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 4rem);
    margin: 1rem 0 0;
  }

  h6 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0.5rem 0;
  }
`;

export interface DocumentCardProps {
  name: string;
  title?: string;
  fileTypes?: string[];
  tags?: string[];
  onChange?: (fileId: string) => void;
  metaFileType?: FileType;
  namePrefix?: string;
  onSelect?: (file: File) => void;
  onClick?: () => void;
  /**
   * Max size in MB
   */
  maxSize?: number;
  icon?: "plus" | "upload";
  showSelectedTitles?: boolean;
  action?: "delete" | "edit";
  ratio?: number;
  camera?: {
    title?: string;
    description?: string;
  };
}

const DocumentCard: FC<DocumentCardProps> = ({
  title = "",
  fileTypes = FilesPicker.defaultFileTypes,
  onChange,
  metaFileType,
  tags = [],
  namePrefix = "",
  maxSize = FilesPicker.defaultMaxSize,
  icon = "upload",
  onSelect,
  showSelectedTitles = true,
  action = "edit",
  ratio = FilesPicker.defaultImageRatio,
  onClick,
  camera
}) => {
  const [previewImageSrc, setPreviewImageSrc] = React.useState<string | null>();
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [fileDetails, setFileDetails] = React.useState<{
    size: string;
    name: string;
    type: "img" | "pdf";
  } | null>(null);

  const picker = useFilesPicker({
    fileTypes,
    imageRatio: ratio,
    metaFileType: metaFileType,
    tags,
    namePrefix,
    maxSize,
    onSelect,
    onChange,
    setLoading,
    setPreviewImageSrc,
    setFileDetails,
    camera
  });

  const showCenterContent = !fileDetails;

  const handleClick = async () => {
    void picker.startPicker({
      title: translate("uploadDocument")
    });
    onClick?.();
  };

  const handleActionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === "delete") {
      setPreviewImageSrc(null);
      onChange?.("");

      // reset input
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }

    if (action === "edit") {
      inputRef.current?.click();
    }
  };

  return (
    <Card
      className="document-card"
      data-loading={loading ? "true" : undefined}
      data-preview={previewImageSrc ? "true" : undefined}
      data-show-selected-titles={showSelectedTitles ? "true" : "false"}
      onClick={() => void handleClick()}
      style={
        {
          "--card-ratio": ratio
        } as CSSProperties
      }
    >
      <Clip>
        {fileDetails?.type === "img" && (
          <ImagePreview
            style={{
              backgroundImage: previewImageSrc
                ? `url(${previewImageSrc})`
                : undefined
            }}
          />
        )}
        {fileDetails?.type === "pdf" && (
          <PreviewDocument>
            <IconDocumentFilePdfGradient />
            {fileDetails.name && (
              <h5 title={fileDetails.name}>{fileDetails.name}</h5>
            )}
            {fileDetails.size && <h6>{fileDetails.size}</h6>}
          </PreviewDocument>
        )}
        {showCenterContent && (
          <Center>
            {icon === "upload" && <IconUpload />}
            {icon === "plus" && <IconLargePlusSimpleAddCross />}
            {title && <Title>{title}</Title>}
          </Center>
        )}
      </Clip>
      <Action data-action={action} onClick={handleActionClick}>
        {action === "edit" && <IconEditPencilPaperWrite />}
        {action === "delete" && <IconCrossXCloseMissed />}
      </Action>
    </Card>
  );
};

export default DocumentCard;
