import type { CSSProperties } from "react";
import React, { useMemo, useRef } from "react";
import styled from "@emotion/styled";
import type { DocumentCardProps } from "src/ui/components/DocumentUploader/DocumentCard";
import DocumentCard from "src/ui/components/DocumentUploader/DocumentCard";
import clsx from "clsx";
import type FileType from "src/constants/fileType";

const Wrap = styled.div`
  width: 100vw;
  transform: translateX(-50%);
  left: 50%;
  position: relative;
  max-width: 100vw;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  padding: 2rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  &[data-scrolling="true"] {
    max-width: min(1000px, calc(100vw));

    @media (min-width: 600px) {
      padding: 0;
      overflow: visible;
      max-width: min(1000px, calc(100vw - 5rem));
    }
  }
`;

const Container = styled.div`
  display: flex;
  width: calc(calc(var(--items, 1) * 100%) + 100vw);
  gap: 1rem;
  overflow: visible;

  > .document-card {
    flex: 1;
    scroll-snap-align: center;
    --width: min(400px, 80vw);
    width: var(--width);
    min-width: var(--width);
    max-width: var(--width);

    &:first-of-type {
      margin-left: 50vw;
    }
    &:last-of-type {
      margin-right: 50vw;
    }
  }

  &[data-scrolling="true"] {
    @media (min-width: 600px) {
      width: 100%;
    }
  }
`;

const Indicators = styled.div`
  display: flex;
  justify-content: center;
  margin: -0.5rem 0 1.5rem;

  &[data-scrolling="true"] {
    @media (min-width: 600px) {
      display: none;
    }
  }

  &[data-hide="true"] {
    display: none;
  }
`;

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  position: relative;
  display: grid;
  place-items: center;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    background-color: var(--greys-light-gray, #e6e3db);
    transition: background-color var(--ease-time) var(--ease-type);
    width: 0.5rem;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &.active:after {
    background-color: var(--color-charcoal, #333);
  }
`;

export interface SingleFileConfig {
  title?: string;
  tags?: string[];
  fileTypes?: string[];
  metaFileType?: FileType;
  namePrefix?: string;
  icon?: DocumentCardProps["icon"];
}

interface PropsConfig<T extends string> {
  fileTypes?: string[];
  files: Record<T, SingleFileConfig>;
}

// default T value is string
interface Props<T extends string> {
  config: PropsConfig<T>;
  onChange?: (values: Record<T, string>, valid: boolean) => void;
  onSelect?: (file: File, values: Record<T, string>) => void;
  display?: {
    ratio?: number;
    showSelectedTitles?: boolean;
    action?: DocumentCardProps["action"];
  };
}

const DocumentUploader = <T extends string>(
  props: Props<T>
): React.JSX.Element => {
  const { config, onChange } = props;

  const documentValues = useRef<Record<string, string>>({});
  const scrollRef = useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const documentList = useMemo<DocumentCardProps[]>(() => {
    const list: DocumentCardProps[] = [];
    for (const key in config.files) {
      const fileConfig = config.files[key];
      list.push({
        name: key,
        title: fileConfig.title,
        fileTypes: fileConfig.fileTypes,
        metaFileType: fileConfig.metaFileType,
        namePrefix: fileConfig.namePrefix,
        tags: fileConfig.tags,
        icon: fileConfig.icon
      });
    }
    return list;
  }, [config]);

  const handleChange = (docProps: DocumentCardProps) => (fileId: string) => {
    documentValues.current[docProps.name] = fileId;

    // check if all values are filled
    const requiredKeys = documentList.map((doc) => doc.name);
    const valid = requiredKeys.every((key) => {
      return documentValues.current[key];
    });

    onChange?.(documentValues.current, valid);
  };

  const handleSelect = (docProps: DocumentCardProps) => (file: File) => {
    const docValues = documentValues.current;
    const withTmpValues = {
      ...docValues,
      [docProps.name]: file
    } as Record<T, string>;
    props.onSelect?.(file, withTmpValues);
  };

  // watch for scrolling
  React.useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const handleScroll = () => {
      const cards = scrollElement.querySelectorAll(".document-card");
      cards.forEach((card, index) => {
        const rect = card.getBoundingClientRect();
        const center = rect.left + rect.width / 2;
        const distance = Math.abs(center - window.innerWidth / 2);
        if (distance < 100) {
          setActiveIndex(index);
        }
      });
    };

    scrollElement.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRef]);

  const scrollToIndex = (index: number) => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const cards = scrollElement.querySelectorAll(".document-card");
    const card = cards[index];

    const rect = card.getBoundingClientRect();
    const center = rect.left + rect.width / 2;
    scrollElement.scrollBy({
      left: center - window.innerWidth / 2,
      behavior: "smooth"
    });
  };

  return (
    <div>
      <Wrap ref={scrollRef}>
        <Container
          style={
            {
              "--items": documentList.length
            } as CSSProperties
          }
        >
          {documentList.map((docProps) => {
            return (
              <DocumentCard
                {...docProps}
                fileTypes={docProps.fileTypes ?? config.fileTypes}
                key={docProps.name}
                onChange={handleChange(docProps)}
                onSelect={handleSelect(docProps)}
                showSelectedTitles={props.display?.showSelectedTitles}
                action={props.display?.action}
                ratio={props.display?.ratio}
                tags={docProps.tags}
              />
            );
          })}
        </Container>
      </Wrap>
      <Indicators data-hide={documentList.length <= 1}>
        {documentList.map((_, index) => (
          <Indicator
            key={index}
            onClick={() => {
              scrollToIndex(index);
            }}
            className={clsx({
              active: index === activeIndex
            })}
          />
        ))}
      </Indicators>
    </div>
  );
};

export default DocumentUploader;
